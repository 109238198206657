<template>
    <div class="ListConpentsBox">
        <van-empty 
            class="custom-image"
            :image="require('./../../assets/Image/custom-empty-image.png')" 
            v-if="list.length <= 0" />
        <van-list
             ref="List"
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
             :immediate-check='false'
            >
            
            <div class="EventBox van-hairline--bottom" v-for="(item,index) in list" :key="index"  @click="Details(item)">
                <div class="ImgBox" >
                    <van-image fit="fill" :src="item.Photos[0]" />	
                </div>
                <div class="BoxEvent">
                    <div class="RowtIitle "> 
                        <strong><van-icon name="wap-home" /></strong><span class="one-txt-cut">{{item.LA_ProjectName}}</span>		
                    </div>
                    <div class="RowtIitle"> 
                        <strong><van-icon name="invition" /></strong>{{item.LA_IName}}	
                    </div>
                    <div class="RowtIitle"> 
                        <strong><van-icon name="underway" /></strong><span class="one-txt-cut">{{item.LA_Time}}</span>		

                        
                    </div>
                    <div class="RowtIitle"> 
                        <p><strong><van-icon name="diamond" /></strong>{{item.LA_AuditStatus}}</p>
                    </div>
                    
                </div>
            </div>
        
        </van-list>
    </div>
</template>
<script>
import { NavBar,Tab, Tabs ,List, Image as VanImage ,Icon ,Empty } from 'vant';
import {Api_YyMAALaboratoryAppointment_GetPage} from '@/api/queryMake'
export default {
    props:['LA_AuditStatus'],
    components:{
        [NavBar.name]: NavBar,
        [Tab.name]: Tab,
        [Tabs.name]: Tabs,
        [List.name]: List,
        [VanImage.name]: VanImage,
        [Icon.name]: Icon,
        [Empty.name]: Empty,
    },
    data() {
        return {
            list:[],
            loading: false,
            finished: false,  
           
            pagesize:'10',
            pageindex:0
        }
    },
    methods:{
        Details(item){
            this.$router.push({path:`/makeDetails/${item.LA_ID}`})
        },
        CheckList(){
            this.list = [];
            this.finished = false;
            this.$nextTick(()=>{
                this.$refs.List.check();
            })
        },
        GetList(){
            Api_YyMAALaboratoryAppointment_GetPage({
                pageindex:this.pageindex,
                pagesize:6,
                LA_AuditStatus:this.LA_AuditStatus
            }).then(res=>{
                this.loading = false;
                this.list = this.list.concat(res.List)
                if(this.list.length >= res.AllConut){
                    this.finished = true
                }
            })
        },
        onLoad() {
            this.loading = true;
            this.pageindex++
			this.GetList()
		},
    }
}
</script>
<style lang="scss" scoped>
.EventBox {
        padding: 3px 0px;
        background: #fff;
        display: flex;
        flex-direction: row;
     
        .ImgBox {
            width: 100px;
            height: 100px;
            overflow: hidden;
            padding:6px;
            .van-image {
                width: 100%;
                height: 100%;
            }
            /deep/ .van-image__img {
                display: inline-block;
                border-radius: 5px;
                width: 100%;
                height: 100%;
                
            }
        }
        .BoxEvent {
            flex: 1;
            padding:6px 5px;
            padding-left: 8px;
            font-size: 13px;
            color: #333;
            overflow: hidden;
            .RowtIitle {
                width: 100%;
                line-height: 25px;
                display: flex;
                flex-direction: row;
                position: relative;
               
                strong {
                    padding-right: 3px;
                    padding-right: 4px;
                    color: #ff9254;
                    font-weight: bold;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    i {
                        font-size: 18px;
                    }
                }
                p:first-child {
                    width: 48%;
                }
                p:last-child {
                    flex: 1;
                }
                p {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
                .StateBox {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    color:#07c160;
                }
                .acitve {
                    color: #ee0a24;
                }
                span {
                    flex: 1;
                   
                }
            }
        }
        
    }
    .custom-image {
        /deep/ .van-empty__image {
            width: 90px;
            height: 90px;
        }
    }
</style>